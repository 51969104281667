import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Layout } from '../component/Layout'
import { PropertyList } from '../component/page_component/PropertyList'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { Helmet } from 'react-helmet'
import hreflangConfig from '../utils/hreflangConfig'

const Search: React.FC<PageProps> = (props: any) => {
  const intl = useIntl()
  const {
    location,
    pageContext: { data },
  } = props

  const hreflangLinks = hreflangConfig['/fr/search/']
  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${location.pathname}`

  return (
    <Layout location={location} title={intl.formatMessage({ id: 'PageTitleSearch' })}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
        <meta
          name="description"
          content="Trouvez votre propriété de luxe idéale parmi notre sélection exclusive de villas, appartements et domaines... Recherchez selon vos critères et explorez des offres sur mesure."
        />
      </Helmet>
      <Grid container justifyContent="center" id="search-dream-destination">
        <PropertyList data={data} location={location} displayHeroSection={false} withFilter />
      </Grid>
    </Layout>
  )
}

export default Search
